import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import GlobalBanner from '../components/globalBanner'
import Layout from '../components/layout'
import _ from 'underscore';
import { hasCookie } from '../services/cookies';

class Team extends Component {

  render() {
    const active_members = this.props.data.allWpPerson;
    const data = this.props.data;

    if (hasCookie() == true) {
      return (
        <Layout>
          <div className="team-template background-secondary">

            <GlobalBanner pageTitle={data.wpPage.titleSubtitleACF.titlePage !== undefined && data.wpPage.titleSubtitleACF.titlePage}
                          pageSubTitle={data.wpPage.titleSubtitleACF.subtitlePage !== undefined && data.wpPage.titleSubtitleACF.subtitlePage}
                          bgSharpImg={data.wpPage.featuredImage.node.localFile !== null && data.wpPage.featuredImage.node.localFile.childImageSharp !== null && data.wpPage.featuredImage.node.localFile}
                          bgImg={data.wpPage.featuredImage.node.sourceUrl}
            />

            <div className="ctn-main uk-padding push-up-mini">

              <div className="member-wrapper">
                <div className="member-good-img">
                  <div className="uk-child-width-1-3@m uk-grid-medium" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; target: .img-ctn img:last-child; delay: 500; repeat: true">
                    {active_members.edges.map(({ node, index }) => (
                      <div key={index}>
                        <div className="uk-card uk-card-default uk-margin uk-margin-bottom">
                            <div className="uk-card-header">
                                <div className="uk-grid-small uk-flex-middle" data-uk-grid>
                                  <div className="uk-width-expand">
                                        <h3 className="uk-card-title uk-margin-remove-bottom">{node.teamsACF.fullName}</h3>
                                        <p className="uk-text-meta uk-margin-remove-top">{node.teamsACF.role}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="img-ctn">
                              <Link className="uk-cover-container" to={`/team/${node.slug}/`}>
                                <img src={node.teamsACF.avatarTeam.sourceUrl} alt={node.title} className="imgPath image-avatar avatar-normal uk-animation-reverse"/>
                                <img src={node.teamsACF.funnyImage.sourceUrl} alt={node.title} className="imgPath image-avatar avatar-funny" />

                              </Link>
                            </div>
                            <div className="uk-card-footer">
                                <Link className="uk-cover-container" to={`/team/${node.slug}/`}>Read more</Link>
                            </div>
                          </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/*<div className="blog-content uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-small" data-uk-grid >*/}
              {/*{active_members.edges.map(({ node }) => (*/}
              {/*<div key={node.id} className="uk-padding-small single-member">*/}
              {/*<div className="uk-card-media-top">*/}
              {/*<Link className="uk-cover-container" to={`/team/${node.slug}/`}>*/}
              {/*{(node.acf.avatar_team.team !== undefined || node.acf.avatar_team.team !== null ) &&*/}
              {/*<img src={node.acf.avatar_team.sourceUrl} alt={node.title} className="image-avatar"/>*/}
              {/*}*/}
              {/*</Link>*/}
              {/*</div>*/}
              {/*<div className="member-hover-middle">*/}
              {/*<Link to={`/team/${node.slug}/`}>*/}
              {/*<h3 className="colored" dangerouslySetInnerHTML={{ __html: node.title }} />*/}
              {/*</Link>*/}
              {/*<div className="summary">*/}
              {/*<p dangerouslySetInnerHTML={{ __html: node.acf.role}} />*/}
              {/*<Link to={`/team/${node.slug}/`}>Profile</Link>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*))}*/}
              {/*</div>*/}

            </div> {/* End .ctn-main.uk-padding */}
          </div>  {/* End .team-template */}
        </Layout>
      );
    } else {
      return (
        <></>
      )
    }
  }
}

export default Team;

export const pageQuery = graphql`
  query {
    wpPage(slug: {eq: "team"}) {
      id
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                tracedSVGOptions: {background: "#1e87f0", color: "#004f99"}
                sizes: "(min-width: 1429px) 1920px, 100vw"
              )
            }
          }
          sourceUrl
        }
      }
      titleSubtitleACF {
        subtitlePage
        titlePage
      }
    }
    allWpPerson (filter: {teamMemberACF: {memberStatus: {eq: "active"}}}) {
      edges {
        node {
          id
          slug
          title
          teamMemberACF {
            memberStatus
          }
          teamsACF {
            fullName
            bio
            role
            nativeCountry
            avatarTeam {
              sourceUrl
            }
            funnyImage {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
